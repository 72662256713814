import styled from "@mui/material/styles/styled"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { alpha } from "@mui/system"
import React from "react"
import { isReactElement } from "../../util/is-react-element"

export const CodeTabs : React.FC = ({children}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabContents = getCodeBlocks(children).map((element) => {
    const title = element.props.children?.props?.className?.replace(/language-/, "") ?? ""
    const props: TabProps =  {title, element}
    return props
  })

  return (
    <TabWrapper>
      <StyledTabs 
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        >
        {tabContents.map((tabItem, index) => {
          return  <StyledTab key={`${index}-${tabItem.title}`} label={tabItem.title} {...a11yProps(index)} />
        })}
      </StyledTabs>
      <TabContentsList>
      {tabContents.map((tabItem, index) => {
        return  <TabContent isSelected={value === index} children={tabItem.element}/>
      })}
      </TabContentsList>
    </TabWrapper>
  );
}

type TabProps = {
  title: string,
  element?: React.ReactElement
}

type TabPanelProps = {
  isSelected: boolean
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const getCodeBlocks = (children: React.ReactNode) => {
  if(!children) return []
  if(Array.isArray(children)) {
    return children.map((child)=>{ 
      return isReactElement(child) ? child : null
    }).filter((item): item is NonNullable<typeof item> => item != null);
  }

  return isReactElement(children) ? [children] : []
}

const TabWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  backgroundColor: alpha(theme.palette.text.primary, 0.15),
  '& .MuiTabs-scrollButtons': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    '&.Mui-disabled': {
      opacity: 1,
      color: theme.palette.text.disabled
    }
  }
}));

const TabContentsList = styled('div')(({ theme }) => ({
  display: 'flex',
  maxHeight: '30em',
  backgroundColor: theme.palette.background.z1,
  overflow: 'auto'
}));

const TabContent = styled('div', {shouldForwardProp: (prop) => prop !== "isSelected"})<TabPanelProps>(({ theme, isSelected }) => ({
  width: isSelected ? '100%' : 0,
  overflowX: isSelected ? 'auto' : 'hidden',
  '&>pre': {
    overflowX: isSelected ? 'auto' : 'hidden'
  }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 'auto'
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  padding: '.5em 1em',
  minHeight: 'auto',
  textTransform: 'none',
  letterSpacing: '1px',
  fontSize: '1rem',
  '&.Mui-selected': {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.background.z1
  }
}));
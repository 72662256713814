
import React, { CSSProperties, memo } from "react"
import styled from "@mui/material/styles/styled";
import { Paper } from "@mui/material";

type Props = {style?: CSSProperties}

export const Note: React.FC<Props> = memo(({children, style}) => <Container children={children} style={style}/>)

const Container = styled(Paper, {shouldForwardProp: (prop) => prop !== "style"})<{style?: CSSProperties}>(({ theme, style }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  margin: `${theme.spacing(2)} 0`,
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body2.fontSize,
  ...style
}));
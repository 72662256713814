import { alpha } from '@mui/material'
import styled from '@mui/material/styles/styled'
import * as React from 'react'
import { MDXContents } from './mdx-contents'
export const MDXArticle: React.FC<Props> = ({ body }) => {
  if (!body) return null
  return (
    <MDXWrapper className="container">
      <MDXContents body={body} />
    </MDXWrapper>
  )
}

type Props = {
  body: string
}

const MDXWrapper = styled('article')(({ theme }) => ({
  margin: 'auto',
  '& a': {
    color: theme.palette.text.link,
    '&:visited': {
      color: theme.palette.text.visitedLink
    }
  },
  '& a.button': {
    color: theme.palette.text.primary,
    '&:visited': {
      color: theme.palette.text.primary
    }
  },
  '& table': {
    borderSpacing: '0 0.4em'
  },
  '& td+td': {
    paddingLeft: '1em'
  },
  '& code': {
    color: theme.palette.text.accent,
    fontFamily: theme.typography.fontFamily,
    marginRight: '0.125em',
    marginLeft: '0.125em',
    padding: '0 0.375em',
    backgroundColor: theme.palette.background.z1,
    borderRadius: theme.shape.borderRadius
  },
  '& h2': {
    ...theme.typography.h4,
    padding: theme.spacing(2),
    margin: `${theme.spacing(4)} 0 ${theme.spacing(2)}`,
    fontSize: '1.8rem',
    color: theme.palette.primary.contrastText,
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: theme.spacing(1),
      height: '100%',
      backgroundColor: theme.palette.primary.main,
    }
  },
  '& p': {
    ...theme.typography.body1,
    lineHeight: 1.7
  },
  '& .mermaid': {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.z1,
    borderRadius: theme.shape.borderRadius
  }
}))
import { useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import styled from '@mui/material/styles/styled'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Index } from '../@types/gatsby-types-ext'
import { SEO } from '../components/common/seo'
import { TableOfContents } from '../components/common/table-of-contents'
import { TagChips } from '../components/common/tag-chips'
import { TwoColumnLayout } from '../components/layout/two-column-layout'
import { MDXArticle } from '../components/mdx/mdx-article'
const ArticleContent: React.FC<PageProps<GatsbyTypes.Query>> = React.memo(({ data }) => {
  const isLarge = useMediaQuery((theme: Theme) =>theme.breakpoints.up('lg'))
  const frontmatter = data.mdx?.frontmatter
  const imageData = getImage(frontmatter?.image?.childImageSharp?.gatsbyImageData)
  const indexes = data.mdx?.tableOfContents?.items as unknown as Index[]
  const indexesLength = indexes?.length ?? 0
  const sideContents = !isLarge || indexesLength < 4 ? null : <TableOfContents indexes={indexes}/>
  const tableOfContents = !isLarge && indexesLength >= 4 ? <TableOfContentsInsideContainer indexes={indexes}/>: null
  return (
    <>
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        imageUrl={frontmatter?.image?.publicURL}
      />
      <HeroImage image={imageData} alt=""  imgClassName="heroImage" objectFit="contain"/>
      <TwoColumnLayout sideContents={sideContents}>
        <Container className="container">
          <Title children={frontmatter?.title} />
          <DateText children={frontmatter?.date}/>
          <TagChips tags={frontmatter?.tags?.map((tag) => tag?.toString()).sort()} />
          {tableOfContents}
          <MDXArticle body={data?.mdx?.body ?? ""} />
        </Container>
      </TwoColumnLayout>
    </>
  )
})

const TableOfContentsInsideContainer = styled(TableOfContents)(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
  [theme.breakpoints.up('lg')]: {
    display: 'none'
  }
}));

const HeroImage = styled(GatsbyImage)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(50),
  maxHeight: '50vh',
  '& .heroImage': {
    height: theme.spacing(50),
    maxHeight: '50vh',
    objectPosition: 'center',
    objectFit: 'contain',
  },
}));

const Title = styled('h1')(({ theme }) => ({
  marginBottom: 0
}));

const DateText = styled('div')(({ theme }) => ({
  ...theme.typography.subtitle2,
  width: '100%',
  marginBottom: theme.spacing(1),
  overflow: 'hidden',
  color: theme.palette.text.secondary,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));


const Container = styled('div')(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
  margin: `${theme.spacing(4)} auto`,
  [theme.breakpoints.up('md')]: {
    padding: 0,
  },
}));

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date
        description
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
            )
          }
          publicURL
        }
        tags
      }
      body
      tableOfContents
    }
  }
`

export default ArticleContent
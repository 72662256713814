
import React, { CSSProperties, memo } from "react"
import styled from "@mui/material/styles/styled";

type Props = {style?: CSSProperties}

export const FlexItems: React.FC<Props> = memo(({children, style}) => <Container children={children} style={style}/>)

const Container = styled('div', {shouldForwardProp: (prop) => prop !== "style"})<{style?: CSSProperties}>(({ theme, style }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  ...style
}));
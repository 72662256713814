import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import React from 'react'
import Highlight, { defaultProps, Language } from 'prism-react-renderer'
import codeTheme from "prism-react-renderer/themes/vsDark"
import { SnsButtons } from '../common/sns-buttons'
import styled from '@mui/material/styles/styled'
import { isReactElement } from '../../util/is-react-element'
import { CodeTabs } from '../common/code-tabs'
import { Link } from 'gatsby'
import { FlexItems } from '../common/flex-items'
import { Note } from '../common/note'

export const MDXContents: React.FC<Props> = ({body}) => (
  <MDXProvider components={{ pre: CodeBlock, SnsButtons, CodeTabs, Link, FlexItems, Note }}>
    <MDXRenderer children={body} />
  </MDXProvider>
)

type Props = {
  body: string
}

const getLanguage = (fileName: string) => {
  switch (true) {
  case !fileName:
    return "markdown" as Language
  case fileName.endsWith(".kt"):
    return "kotlin" as Language
  case fileName.endsWith(".xml"):
    return "xml" as Language
  case fileName.endsWith(".gradle"):
    return "groovy" as Language
  default:
    return fileName as Language
  }
}

const CodeBlock: React.FC = ({ children }) => {
  if(!isReactElement(children) || children.props.originalType !== "code") return (<>{children}</>)
  const code = children.props
  const fileName = code.className?.replace(/language-/, "")
  const language = getLanguage(fileName)

  return (
    <Highlight
      {...defaultProps}
      code={code.children}
      language={language as Language}
      theme={codeTheme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <CodeBlockInner
          className={className}
          style={style}
          title={fileName}
        >
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => {
                const tokenProps = getTokenProps({ token, key })
                // Remove line breaks from last line
                if(i == tokens.length - 1 && tokenProps.children == "\n") return null
                return(
                  <span key={key} {...tokenProps} />
                )
              })}
            </div>
          ))}
        </CodeBlockInner>
      )}
    </Highlight>
  )
}

const CodeBlockInner = styled('pre', {shouldForwardProp: (prop) => prop !== "style" && prop !== "title"})<{title: string, style: React.CSSProperties}>(({ theme, title, style }) => ({
  ...style,
  width: '100%',
  height: '100%',
  padding: "1em",
  margin: 0,
  backgroundColor: 'transparent',
  overflowX: 'auto',
}));
import { Link } from "gatsby"
import React, { memo } from "react"
import styled from "@mui/material/styles/styled";
import Twitter from "@mui/icons-material/Twitter";
import GitHub from "@mui/icons-material/GitHub";
import IconButton from "@mui/material/IconButton";

export const SnsButtons: React.FC = memo(() => {
  return(
    <Container>
      <IconButton component="a" href="https://twitter.com/shirobutton" className="button" aria-label="Twitter">
        <TwitterIcon fontSize="large" />
      </IconButton>
      <IconButton component={GitHubButton} to="https://github.com/shirobutton" className="button" aria-label="GitHub">
        <GitHubIcon fontSize="large" />
      </IconButton>
    </Container>
  )
})

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

const TwitterIcon = styled(Twitter)(({ theme }) => ({
  color: "#1DA1F2"
}));

const GitHubButton = styled(Link)(({ theme }) => ({
  color: "#fff"
}));

const GitHubIcon = styled(GitHub)(({ theme }) => ({
  color: "#fff"
}));



import styled from "@mui/material/styles/styled"
import React, { memo } from "react"
import { Index } from "../../@types/gatsby-types-ext"
import { scroller } from 'react-scroll'
import { ListItemButton } from "@mui/material"

export const TableOfContents: React.FC<{indexes: Index[], className?: string}> = memo(({indexes, className}) => {
  const components = indexes?.map((index) => {
    const scroll = () => {
      scroller.scrollTo(index.url.replace('#', ''),{
        offset: -80,
        duration: 300,
        smooth: true
      })
    }
    return <StyledListItem key={index.url} children={index.title} onClick={scroll} />
  })
  return(<Container children={components} className={className}/>)
})

const Container = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  listStyleType: 'none',
  '& li+li': {
    marginTop: theme.spacing(2)
  }
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
